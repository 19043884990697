/* A little larger inputs to fit the date */
.daterangepicker .ranges {
    width: 180px;
}

.daterangepicker .ranges .input-mini {
    width: 80px;
}
/* /A little larger inputs to fit the date */

.daterangepicker .range_inputs {
/* buttons should be aligned with predefined ranges */
    margin-left: 10px;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
    background-color: #44A7A2;
    border-color: #44A7A2;
}

.daterangepicker .range_inputs button,
.daterangepicker .range_inputs button:focus, {
    background-color: #339999 !important;
    border-color: #339999 !important;
    color: #fff;
}

.daterangepicker .range_inputs button:hover {
    background-color: #6DC8C3 !important;
    border-color: #6DC8C3 !important;
    color: #fff;
}
